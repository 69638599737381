@layer components {
  a {
    color: var(--link-text);
  }

  a:not(.btn):not([href]),
  a:not(.btn):not([href]):hover {
    @apply text-normal no-underline;
  }

  a:hover {
    color: var(--link--hover-text);
    text-decoration: underline;
  }

  /* Default styles for paragraphs and headings based on prose / tailwind typography */
  /* get out of your way automatically as soon as any class is assigned */

  p:not([class]) {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  h2:not([class]),
  .h2-default {
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }

  h3:not([class]) {
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }

  h4:not([class]) {
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  h5:not([class]) {
    font-weight: 600;
    margin-top: 1em;
    font-size: 0.95em;
    margin-bottom: 0.25em;
    line-height: 1.5;
  }

  h6:not([class]) {
    font-weight: 600;
    margin-top: 0.5em;
    font-size: 0.85em;
    margin-bottom: 0;
    line-height: 1;
  }

  p:not([class]):first-child,
  h2:not([class]):first-child,
  h3:not([class]):first-child,
  h4:not([class]):first-child {
    @apply mt-0;
  }

  p:not([class]):last-child,
  h2:not([class]):last-child,
  h3:not([class]):last-child,
  h4:not([class]):last-child {
    @apply mb-0;
  }
}
